export const wmIT = {
  'skitouring': 'Scialpinismo',
  'horse': 'A cavallo',
  'walking': 'Passeggiata',
  'running': 'Corsa',
  'asphalt': 'Asfalto',
  'bitumenduro': 'Bitumenduro',
  'onoff': 'Onoff',
  'real_dirt': 'Sterrato vero',
  'bar': 'Bar',
  'cycling': 'In bicicletta',
  'poi_type': 'Punti di interesse',
  'where': 'Luoghi',
  'lang': 'Lingua',
  'langPlaceholder': 'Seleziona',
  'from': 'Partenza',
  'to': 'Arrivo',
  'ascent': 'Dislivello positivo',
  'descent': 'Dislivello negativo',
  'distance': 'Distanza',
  'ele_from': 'Quota di partenza',
  'ele_max': 'Quota massima',
  'ele_min': 'Quota minima',
  'ele_to': 'Quota di arrivo',
  'difficulty': 'Difficoltà',
  'cai_scale': 'Scala di difficoltà CAI',
  'duration_forward': 'Durata di andata',
  'duration_backward': 'Durata di ritorno',
  'Filtri': 'Filtri',
  'Cerca': 'Ricerca',
  'tracce': 'Tracce',
  'hiking': 'Escursionismo',
  'Escursionismo': 'Escursionismo',
  'escursionismo': 'Escursionismo',
  'i miei percorsi': 'Disegna percorso',
  'metri': 'Metri',
  'Vedi su OpenStreetMap': 'Vedi su OpenStreetMap',
  'Descrizione Audio': 'Descrizione Audio',
  'il tuo browser non supporta gli elementi audio':
    'Il tuo browser non supporta gli elementi audio.',
  'stampa': 'Stampa',
  'Difficoltà': 'Difficoltà',
  'Scala di difficoltà CAI': 'Échelle de difficulté CAI',
  'Questo percorso non è accessibile': 'Questo percorso non è accessibile',
  'Descrizione': 'Descrizione',
  'Punto di partenza': 'Punto di partenza',
  'Punto di arrivo': 'Punto di arrivo',
  'Dettagli tecnici': 'Dettagli tecnici',
  'Attività': 'Attività',
  'Come arrivare': 'Come arrivare',
  'Bici muscolari': 'Bici muscolari',
  'Bici elettriche': 'Bici elettriche',
  'Mezzi pubblici': 'Mezzi pubblici',
  'Auto Propria': 'Auto Propria',
  'Punti di interesse': 'Punti di interesse',
  'Collegamenti esterni': 'Collegamenti esterni',
  'Downloads': 'Downloads',
  'edit geohub': 'Modifica geohub',
  'NCC': 'NCC',
  'Filtri attivi': 'Filtri attivi',
  'Reset filtri': 'Reset filtri',
  'Torna alla home': 'Torna alla home',
  'Risultati': 'Risultati',
  'Caricamento in corso.': 'Caricamento in corso.',
  'Spiacenti non ci sono risultati con questi criteri di ricerca.':
    'Spiacenti non ci sono risultati con questi criteri di ricerca.',
  'duration': 'Durata',
  'Bentornato!': 'Bentornato!',
  'Accedi con le tue credenziali per continuare.': 'Accedi con le tue credenziali per continuare.',
  'E-mail': 'E-mail',
  "L'e-mail è obbligatoria": "L'e-mail è obbligatoria",
  "Hai inserito un'e-mail non valida": "Hai inserito un'e-mail non valida",
  'Password': 'Password',
  'La password è obbligatoria': 'La password è obbligatoria',
  'Accedi': 'Accedi',
  'Hai dimenticato la password?': 'Hai dimenticato la password?',
  'Accedi e scarica i tuoi percorsi preferiti': 'Accedi e scarica i tuoi percorsi preferiti',
  'Puoi anche condividerli con i tuoi compagni di viaggio':
    'Puoi anche condividerli con i tuoi compagni di viaggio',
  'Registrati': 'Registrati',
  'Tracce scaricate': 'Tracce scaricate',
  'Utilizzo dati': 'Utilizzo dati',
  'Registrazioni': 'Registrazioni',
  'Photos': 'Foto',
  'Waypoint': 'Waypoint',
  'elimina': 'elimina',
  'Nome': 'Nome',
  'inserisci il tuo nome': 'inserisci il tuo nome',
  "E' necessario inserire un nome": "E' necessario inserire un nome",
  'Email': 'Email',
  "E' necessario inserire una email": "E' necessario inserire una email",
  "L'email inserita non è un'email valida": "L'email inserita non è un'email valida",
  'Codice Fiscale': 'Codice Fiscale',
  'Perché ti chiediamo il CF?': 'Perché ti chiediamo il CF?',
  'Il codice fiscale inserito non è valido': 'Il codice fiscale inserito non è valido',
  'inserisci password': 'inserisci password',
  "E' necessario inserire la password": "E' necessario inserire la password",
  'Conferma password': 'Conferma password',
  'Ripeti password': 'Ripeti password',
  "E' necessario confermare la password": "E' necessario confermare la password",
  'La conferma non corrisponde con la password inserita sopra':
    'La conferma non corrisponde con la password inserita sopra',
  'Cliccando su "Registrati" accetti la nostra': 'Cliccando su "Registrati" accetti la nostra',
  'e i': 'e i',
  'Termini e condizioni': 'Termini e condizioni',
  "Errore durante la registrazione dell'utente": "Errore durante la registrazione dell'utente",
  'Registrazione in corso': 'Registrazione in corso',
  'Perchè ti chiediamo il Codice Fiscale?': 'Perchè ti chiediamo il Codice Fiscale?',
  'Se sei Socia/o CAI inserisci il tuo CF al momento della registrazione. Per te il download delle tappe del Sentiero Italia CAI sarà automaticamente gratuito!':
    'Se sei Socia/o CAI inserisci il tuo CF al momento della registrazione. Per te il download delle tappe del Sentiero Italia CAI sarà automaticamente gratuito!',
  "Origine dell'immagine": "Origine dell'immagine",
  'Annulla': 'Annulla',
  'Dalla libreria': 'Dalla libreria',
  'Scatta una foto': 'Scatta una foto',
  'placeholder': 'Cerca',
  'Carica File': 'Carica File',
  'Trascina qui il tuo file o clicca per selezionare':
    'Trascina qui il tuo file o clicca per selezionare',
  'Accetta i seguenti formati': 'Accetta i seguenti formati',
  'Tipo di file non supportato': 'Tipo di file non supportato',
  'Il file non contiene una geometria valida': 'Il file non contiene una geometria valida',
  'Errore durante la lettura del file, riprova': 'Errore durante la lettura del file, riprova',
  'Si è verificato un errore durante il salvataggio della traccia. Riprova':
    'Si è verificato un errore durante il salvataggio della traccia. Riprova',
  'La traccia è stata salvata correttamente': 'La traccia è stata salvata correttamente',
};
