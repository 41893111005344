// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wm-excerpt {
  padding: 20px 0px 3px 0px;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/wm-core/projects/wm-core/src/excerpt/excerpt.component.scss"],"names":[],"mappings":"AAAA;EACE,yBAAA;AACF","sourcesContent":[".wm-excerpt {\n  padding: 20px 0px 3px 0px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
