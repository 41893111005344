export const wmES = {
  'skitouring': 'Esquí de travesía',
  'horse': 'A caballo',
  'walking': 'Caminata',
  'running': 'Correr',
  'cycling': 'Ciclismo',
  'poi_type': 'Puntos de interés',
  'where': 'Lugares',
  'lang': 'Idioma',
  'langPlaceholder': 'Seleccionar',
  'from': 'Inicio',
  'to': 'Fin',
  'ascent': 'Ascenso +',
  'descent': 'Descenso -',
  'distance': 'Distancia',
  'ele_from': 'Elevación de inicio',
  'ele_max': 'Elevación máxima',
  'ele_min': 'Elevación mínima',
  'ele_to': 'Elevación de llegada',
  'difficulty': 'Dificultad',
  'duration_forward': 'Duración de ida',
  'duration_backward': 'Duración de vuelta',
  'Filtri': 'Filtros',
  'Cerca': 'Buscar',
  'tracce': 'Rutas',
  'hiking': 'Senderismo',
  'Escursionismo': 'Senderismo',
  'escursionismo': 'Senderismo',
  'i miei percorsi': 'Mis rutas',
  'metri': 'Metros',
  'Vedi su OpenStreetMap': 'Ver en OpenStreetMap',
  'Descrizione Audio': 'Descripción de audio',
  'il tuo browser non supporta gli elementi audio': 'Tu navegador no soporta el elemento de audio.',
  'stampa': 'Imprimir',
  'Difficoltà': 'Dificultad',
  'Scala di difficoltà CAI': 'Escala de dificultad CAI',
  'Questo percorso non è accessibile': 'Esta ruta no es accesible',
  'Descrizione': 'Descripción',
  'Punto di partenza': 'Punto de partida',
  'Punto di arrivo': 'Punto de llegada',
  'Dettagli tecnici': 'Detalles técnicos',
  'Attività': 'Actividades',
  'Come arrivare': 'Cómo llegar',
  'Bici muscolari': 'Bicicletas convencionales',
  'Bici elettriche': 'Bicicletas eléctricas',
  'Mezzi pubblici': 'Transporte público',
  'Auto Propria': 'Automóvil propio',
  'Punti di interesse': 'Puntos de interés',
  'Collegamenti esterni': 'Enlaces externos',
  'Downloads': 'Descargas',
  'edit geohub': 'Editar geohub',
  'NCC': 'NCC',
  'Filtri attivi': 'Filtros activos',
  'Reset filtri': 'Restablecer filtros',
  'Torna alla home': 'Volver a la página principal',
  'Caricamento in corso.': 'Cargando.',
  'Spiacenti non ci sono risultati con questi criteri di ricerca.':
    'Lo sentimos, no hay resultados con estos criterios de búsqueda',
  'Bentornato!': 'Bienvenido de nuevo!',
  'Accedi con le tue credenziali per continuare.':
    'Inicie sesión con sus credenciales para continuar.',
  'E-mail': 'E-mail',
  "L'e-mail è obbligatoria": 'Se requiere correo electrónico',
  "Hai inserito un'e-mail non valida": 'Has introducido un correo electrónico no válido',
  'Password': 'Contraseña',
  'La password è obbligatoria': 'La contraseña es obligatoria',
  'Accedi': 'Acceso',
  'Hai dimenticato la password?': '¿Has olvidado tu contraseña?',
  'Accedi e scarica i tuoi percorsi preferiti': 'Inicia sesión y descarga tus rutas favoritas',
  'Puoi anche condividerli con i tuoi compagni di viaggio':
    'También puedes compartirlos con tus compañeros de viaje.',
  'Registrati': 'Regístrate',
  'Tracce scaricate': 'Pistas descargadas',
  'Utilizzo dati': 'Uso de datos',
  'Registrazioni': 'Grabaciones',
  'Photos': 'Photos',
  'Waypoint': 'Waypoint',
  'Nome': 'Nombre',
  'inserisci il tuo nome': 'Inserta tu nombre',
  "E' necessario inserire un nome": 'Se debe introducir un nombre',
  'Email': 'Email',
  "E' necessario inserire una email": 'Es necesario insertar un correo electrónico',
  "L'email inserita non è un'email valida": 'El correo electrónico no es válido',
  'Codice Fiscale': 'Código Fiscal',
  'Perché ti chiediamo il CF?': '¿Por qué te pedimos el Código Fiscal?',
  'Il codice fiscale inserito non è valido': 'El código fiscal no es válido',
  'inserisci password': 'Introducir contraseña',
  "E' necessario inserire la password": 'Se debe ingresar una contraseña',
  'Conferma password': 'Confirmar Contraseña',
  'Ripeti password': 'Repita la contraseña',
  "E' necessario confermare la password": 'Se requiere confirmación de contraseña',
  'La conferma non corrisponde con la password inserita sopra':
    'La confirmación no coincide con la contraseña ingresada anteriormente',
  'Cliccando su "Registrati" accetti la nostra': 'Al hacer clic en "Registrarse" acepta nuestra',
  'e i': 'e i',
  'Termini e condizioni': 'Términos y condiciones',
  "Errore durante la registrazione dell'utente": 'Error durante el registro del usuario',
  'Registrazione in corso': 'Inscripción en proceso',
  'Perchè ti chiediamo il Codice Fiscale?': '¿Por qué te pedimos el Código Fiscal?',
  'Se sei Socia/o CAI inserisci il tuo CF al momento della registrazione. Per te il download delle tappe del Sentiero Italia CAI sarà automaticamente gratuito!':
    'Si eres Socio CAI, introduce tu CF al registrarte. ¡La descarga de las etapas del CAI Sentiero Italia será automáticamente gratuita para ti!',
  "Origine dell'immagine": 'Fuente de la imagen',
  'Annulla': 'Cancelar',
  'Dalla libreria': 'De la biblioteca',
  'Scatta una foto': 'Toma una fotografía',
};
